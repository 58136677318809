<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add New" }} Season</v-card-title
      >

      <v-divider></v-divider>

      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="season-form">
          <v-text-field
            label="Date"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>

          <v-select
            label="Breeding/Resting"
            v-model="fields.season_type"
            :items="seasons"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('season_type')"
            :error-messages="errors['season_type']"
          ></v-select>

          <v-textarea
            label="Comments"
            v-model="fields.comment"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('comment')"
            :error-messages="errors['comment']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="season-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        comment: null,
        season_type: null,
        date: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      seasons: ["Breeding", "Resting"],
    };
  },

  methods: {
    openForm: function (season = null) {
      if (season !== null) {
        this.season = season;
        this.fields.season_type = season.season_type;
        this.fields.comment = season.comment;
        this.fields.date = season.start_date;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.seasonId = this.season.id;
      }

      this.$store
        .dispatch("lhl/dogs/saveSeason", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.comment = null;
      this.fields.date = null;
      this.fields.season_type = null;
    },
  },
};
</script>
