<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Plan Litter</v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="plan-litter-form">
          <v-autocomplete
            label="Dam*"
            v-model="fields.dam_id"
            :items="dams"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('dam_id')"
            :error-messages="errors['dam_id']"
            :disabled="true"
          ></v-autocomplete>

          <v-select
            label="Sire*"
            v-model="fields.sire_id"
            :items="sires"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('sire_id')"
            :error-messages="errors['sire_id']"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search Sire"
                  v-model="sireSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <v-divider></v-divider>
            </template>
          </v-select>

          <v-select
            label="Size"
            v-model="fields.size_id"
            :items="sizes"
            item-text="name"
            item-value="id"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('size_id')"
            :error-messages="errors['size_id']"
          ></v-select>

          <v-autocomplete
            label="Colour"
            v-model="selectedColors"
            :items="colors"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
            :error="errors.hasOwnProperty('colors')"
            :error-messages="errors['colors']"
          ></v-autocomplete>

          <v-select
            label="Coat Type"
            v-model="selectedCoatTypes"
            :items="coat_types"
            item-text="name"
            item-value="id"
            multiple
            outlined
            chips
            background-color="white"
            :error="errors.hasOwnProperty('coat_types')"
            :error-messages="errors['coat_types']"
          ></v-select>

          <v-text-field
            label="Predicted # of Puppies"
            v-model="fields.predicted"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('predicted')"
            :error-messages="errors['predicted']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="plan-litter-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        dam_id: null,
        sire_id: null,
        season_start_date: null,
        season_id: null,
        name: null,
        colors: [],
        coat_types: [],
        size_id: null,
        predicted: null,
      },
      sireSearchTerm: "",
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      seasons: ["Breeding", "Resting"],
    };
  },

  computed: {
    selectedColors: {
      get() {
        return this.fields.colors.map((e) => {
          return e;
        });
      },

      set(colors) {
        this.fields.colors = colors.filter((e) => {
          return colors.includes(e);
        });
      },
    },

    selectedCoatTypes: {
      get() {
        return this.fields.coat_types.map((e) => {
          return e;
        });
      },

      set(coat_types) {
        this.fields.coat_types = coat_types.filter((e) => {
          return coat_types.includes(e);
        });
      },
    },

    sires() {
      let sires = this.$store.getters["lhl/dogs/sires"];

      if (this.sireSearchTerm !== "") {
        sires = sires.filter((c) => {
          const name = c.name.toLowerCase();
          const sireSearchTerm = this.sireSearchTerm.toLowerCase();
          return name.includes(sireSearchTerm);
        });
      }
      return sires;
    },

    dams() {
      return this.$store.getters["lhl/dogs/dams"];
    },

    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },

    breeds() {
      return this.$store.state.lhl.breeds["breeds"];
    },

    coat_types() {
      let types = this.$store.state.lhl.dogs["coat_types"];

      types = types.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return types;
    },

    sizes() {
      let sizes = this.$store.state.lhl.dogs["sizes"];

      sizes = sizes.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return sizes;
    },

    colors() {
      let colors = this.$store.state.lhl.dogs["colors"];

      colors = colors.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return colors;
    },

    markings() {
      let markings = this.$store.state.lhl.dogs["markings"];

      markings = markings.filter((a) => {
        return a.breed_id == this.dog.breed_id;
      });

      return markings;
    },
  },

  methods: {
    openForm: function (season) {
      this.fields.dam_id = this.dog.id;

      this.fields.season_start_date = season.start_date;
      this.fields.season_id = season.id;

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("lhl/dogs/saveLitter", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.value = null;

      this.fields.dam_id = null;
      this.fields.sire_id = null;
      this.fields.season_start_date = null;
      this.fields.season_id = null;
      this.fields.name = null;
      this.fields.colors = [];
      this.fields.coat_types = [];
      this.fields.size_id = null;
      this.fields.predicted = null;
    },
  },
};
</script>
