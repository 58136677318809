<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6" v-if="dog.seasons.length > 0">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">List of Seasons</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="mr-2"
                    @click="openDelete(dog.seasons[dog.seasons.length - 1])"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Delete Last Season</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="openAddSeason()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Append Season</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="dog.seasons"
              no-data-text="No Tasks"
              :items-per-page="-1"
              :item-class="row_classes"
            >
              <template v-slot:item.start_date="{ item }">
                {{ formatDate(item.start_date) }}
              </template>

              <template v-slot:item.litter="{ item }">
                <div v-if="item.season_type == 'Breeding'">
                  <div v-if="item.litter">
                    <v-btn
                      small
                      depressed
                      color="blue lighten-4 blue--text"
                      :to="{
                        name: 'module-lhl-litters-individual',
                        params: { litterId: item.litter.id },
                      }"
                    >
                      <v-icon small>mdi-eye</v-icon> {{ item.litter.name }}
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      small
                      depressed
                      color="green lighten-4 green--text"
                      @click="$refs.planLitter.openForm(item)"
                    >
                      <v-icon small>mdi-calendar</v-icon> Plan Litter
                    </v-btn>
                  </div>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.seasonForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <div v-else>
            <p>There are no Seasons for this dog.</p>

            <v-btn
              v-if="dog.dob"
              small
              depressed
              color="green lighten-4 green--text"
              @click="generateSeason"
            >
              <v-icon small>mdi-calendar</v-icon> Generate Seasons
            </v-btn>

            <div v-else>
              To Generate Seasons you must enter a date of birth for this Dog
            </div>
          </div>
        </v-col>
      </v-row>
      <SeasonForm ref="seasonForm" />
      <PlanLitter ref="planLitter" />

      <v-dialog
        scrollable
        v-model="deleteSeason.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Delete Season</v-card-title>
          <v-card-text
            >Are you Sure you want to Delete the last season?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteSeason.loading"
              @click="saveDelete()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        scrollable
        v-model="addSeason.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>You are about to append a new season</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetAdd">No, cancel</v-btn>
            <v-btn
              color="success"
              depressed
              :loading="addSeason.loading"
              @click="saveAdd()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import SeasonForm from "../components/SeasonForm";
import PlanLitter from "../components/PlanLitter";
import moment from "moment";

export default {
  components: {
    SeasonForm,
    PlanLitter,
  },

  data() {
    return {
      tableHeaders: [
        { text: "Start Date", value: "start_date" },
        { text: "Breeding/Resting", value: "season_type" },
        { text: "Litter", value: "litter" },
        { text: "Comments", value: "comment" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      addSeason: {
        dialog: false,
        model: {},
        loading: false,
      },
      deleteSeason: {
        dialog: false,
        model: {},
        loading: false,
      },
    };
  },

  methods: {
    row_classes(item) {
      var a = moment(new Date(), "YYYY-MM-DD");
      var b = moment(item.start_date, "YYYY-MM-DD");

      if (b < a && item.date_altered == 0) {
        return "lighten-5 red";
      } else if (item.date_altered == 1) {
        return "lighten-5 green";
      }
    },

    generateSeason() {
      const appId = this.$route.params.id;

      const dogId = this.$route.params.dogId;

      this.$store
        .dispatch("lhl/dogs/generateSeason", {
          appId,
          dogId,
        })
        .then(() => {})
        .catch(() => {});
    },
    openDelete(model) {
      this.deleteSeason.model = model;
      this.deleteSeason.dialog = true;
    },

    resetDelete() {
      this.deleteSeason.dialog = false;
      this.deleteSeason.model = {};
      this.deleteSeason.loading = false;
    },

    saveDelete() {
      this.deleteSeason.loading = true;

      let playloadUrl = null;
      let args = [];

      playloadUrl = "lhl/dogs/deleteLastSeason";
      args = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        seasonId: this.deleteSeason.model.id,
      };

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteSeason.loading = false;
        });
    },

    openAddSeason() {
      this.addSeason.dialog = true;
    },

    resetAdd() {
      this.addSeason.dialog = false;
      this.addSeason.loading = false;
    },

    saveAdd() {
      this.addSeason.loading = true;

      let playloadUrl = null;
      let args = [];

      playloadUrl = "lhl/dogs/saveSeason";
      args = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        seasonId: this.addSeason.model.id,
      };

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetAdd();
        })
        .catch(() => {
          this.addSeason.loading = false;
        });
    },
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },
};
</script>
